import { useState, useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import { purchasePaymentOutActions } from "../../../store/purchasePayment-slice";
import { supplierActions } from "../../../store/supplier-slice";

import axios from "axios";
import DashboardContainer from "../../UI/DashboardContainer";
import { variables } from "../../../Variables";
import theme from "../../UI/Theme";
//import BarChart from "../BarChart";

import Title from "../../Title";
import {
  MyTable,
  MyTableRow,
  MyTable2,
} from "../../UI/styledComponents/StyledComponents";
import AddPaymentSteps from "../../paymentsComponent/AddPaymentSteps";
import AddPurchasePaymentStep_1 from "../../purchaseComponents/AddPurchasePaymentStep_1";
import AddPurchasePaymentStep_2 from "../../purchaseComponents/AddPurchasePaymentStep_2";
import AddPurchasePaymentStep_3 from "../../purchaseComponents/AddPurchasePaymentStep_3";


//import ChangePayDateSteps from "../../paymentsComponent/ChangePayDateSteps";
//import ChangePayDateStep_1 from "../../paymentsComponent/ChangePayDateStep_1";
//import ChangePayDateStep_2 from "../../paymentsComponent/ChangePayDateStep_2";
import ChangePayDateStep_3 from "../../paymentsComponent/ChangePayDateStep_3";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

import Draggable from "react-draggable";

//-----IMPORTS MUI ------------------------
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
//import TableCell from "@mui/material/TableCell";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ShareIcon from '@mui/icons-material/Share';

import Collapse from "@mui/material/Collapse";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: theme.palette.common.ludisMain,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const DisplayExtraInfo = (props) => {
  const { open1, item } = props;

  return (
    <TableRow>
      {/* Extra information collapsable  */}
      {/* <TableRow key={item.CLIENTPAYMENT_ID}> */}
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse
          in={open1[item.pOrder.PURCHASEINVOICE_ID]}
          timeout="auto"
          unmountOnExit
        >
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              Purchase Inv. Details....
            </Typography>

            <MyTable2 size="small" aria-label="payments">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#fff" }}>
                  <TableCell>Date Recorded</TableCell>
                  <TableCell>Date Bank</TableCell>
                  <TableCell>Purchase Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={item.pOrder.PURCHASEINVOICE_ID}>
                  <TableCell component="th" scope="row">
                    {/* {new Date(item.PAYMENTDATE).toLocaleString("default", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })} */}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {/* {new Date(item.DATEONSTATEMENT).toLocaleString("default", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })} */}
                  </TableCell>
                  <TableCell>{item.pOrder.PURCHASEINVOICE_DESCRIPTION.substring(0, 60)}</TableCell>
                </TableRow>
              </TableBody>
            </MyTable2>
          </Box>
        </Collapse>
      </TableCell>
      {/* End Extra information collapsable */}
    </TableRow>
  );
};

export default function PurchaseInvoicesReportData(props) {
  const dispatch = useDispatch();
  const currentPurchaseInvoceSelected = useSelector(
    (state) => state.purchasePaymentOut.purchasePaymentOutInfo
  );

  const currentSupplierSelected = useSelector(
    (state) => state.supplier.supplierInfo
  );

  //console.log("purchase Invoice selected....",currentPurchaseInvoceSelected)
  //console.log("purchase Supplier selected....",currentSupplierSelected)

  //const [paymentsOnOrder, setPaymentsOnOrder] = useState([]);

  //const [isLoading1, setIsLoading1] = useState(false);

  //const [isLoading2, setIsLoading2] = useState(false);

  //const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));

  const [showPurchaseInvoicesList, setShowPurchaseInvoicesList] =
    useState(true);
  const [totalPayments, setTotalPayments] = useState(null);

  //variables for the popover (hoover)
  const [paymentRef, setPaymentRef] = useState("");

  const [showAddPurchasePayment, setShowAddPurchasePayment] = useState(false);
  const [showSendPurchaseInvXero, setShowSendPurchaseInvXero] = useState(false);

  const [xeroStatus, setXeroStatus] = useState(false);

  //isLoading1_allPayOnOrder is to pass to the parent component Income.js info about loading to share with sibling componente paymentinfoOrderSummary.js
  const {
    purchaseInvBySupplierByDate,
    purchaseInvPaymentsBySupplierByDate,
    purchasePaymentsTypeList,
    allPurchaseInvoicesWithPaymentsFull,
    isLoading1,
    showPayDate,
    matches,
    isUpdatedPurchasePayment,
    //updatedPurchasePayment,
    //showAddPaymentModalHandler,
    //isNewPurchasePayment,
    socket,
  } = props;

  //console.log props....
  if (allPurchaseInvoicesWithPaymentsFull.length > 0) {
    //console.log("FROM THE PROPS....allPurchaseInvoicesWithPaymentsFull",allPurchaseInvoicesWithPaymentsFull)
    //console.log("FROM THE PROPS....allPurchaseInvoicesWithPaymentsFull",allPurchaseInvoicesWithPaymentsFull[0].pOrder.PURCHASEINVOICE_PAYDAY)
  } else {
    //console.log("FROM THE PROPS....allPurchaseInvoicesWithPaymentsFull = EMPTY ARRAY ")
  }
  //console.log("FROM THE PROPS....purchaseInvBySupplierByDate",purchaseInvBySupplierByDate)

  let repDate = "";
  let repDate1 = "";
  let repDate2 = "";

  let variableDate1 = "";
  let variableDate2 = "";

  const showPaymentsHandler = (event) => {
    event.preventDefault();
    setShowPurchaseInvoicesList((prev) => !showPurchaseInvoicesList);
  };

  const resetPurchasePaymentInfo = () => {
    dispatch(
      purchasePaymentOutActions.resetPurchasePaymentOut({
        purchasePaymentDate: new Date().toISOString(),
      })
    );
  };

  //Update Paymnet In details into the slice---------------------
  const updatePurchasePaymentInfo = (purchasePaymentInfo) => {
    //console.log("Purchase payment info insde the update function....", purchasePaymentInfo);
    //console.log("Purchase payment info insde the update function....", purchasePaymentInfo.pOrder.PURCHASEINVOICE_ID);

    //console.log("this is the Item in the function...",purchasePaymentInfo)

    //if (purchasePaymentInfo) {
    if (Object.keys(purchasePaymentInfo).length !== 0) {
      //Add the Selected Order to the Payments In information
      dispatch(
        purchasePaymentOutActions.updatePurchasePaymentOut({
          //paymentDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(), //---here so far....
          selectedPurchaseInvoice: [
            {
              // "confirmationId":paymentInfo.CONFIRMATION_ID
              PURCHASEINVOICE_ID: purchasePaymentInfo.pOrder.PURCHASEINVOICE_ID,
              PURCHASEINVOICE_NUMBER:
              purchasePaymentInfo.pOrder.PURCHASEINVOICE_NUMBER,
              SUPPLIER_ID: purchasePaymentInfo.SUPPLIER_ID,
              //new added info....
              PURCHASEINVOICE_NETPRICE: purchasePaymentInfo.pOrder.PURCHASEINVOICE_NETPRICE,
              PURCHASEINVOICE_VAT: purchasePaymentInfo.pOrder.PURCHASEINVOICE_VAT,
              PURCHASEINVOICE_TOTAL: purchasePaymentInfo.pOrder.PURCHASEINVOICE_TOTAL,
              PURCHASEINVOICE_DATE: purchasePaymentInfo.pOrder.PURCHASEINVOICE_DATE,
              PURCHASEINVOICE_DESCRIPTION: purchasePaymentInfo.pOrder.PURCHASEINVOICE_DESCRIPTION,
              PURCHASEINVOICE_PAYDAY:purchasePaymentInfo.pOrder.PURCHASEINVOICE_PAYDAY,
              PURCHASEINVOICE_VAT:purchasePaymentInfo.pOrder.PURCHASEINVOICE_VAT

            },
          ],
          
        })
      );
    } else {
      resetPurchasePaymentInfo();
      //console.log("error, date not usable only 2 options available");
      //return;
    }
  };

  //Show the modal with form to submit Purchase Invoice payment-----------
  const showPurchasePaymentModalHandler = (event, action, item) => {

    resetPurchasePaymentInfo();
    if (event) {
      event.preventDefault();
      //console.log("Purchase Invoice Id here....", item.pOrder.PURCHASEINVOICE_ID);
    }
    if (action === 1) {
      setShowAddPurchasePayment((prev) => !showAddPurchasePayment);
      updatePurchasePaymentInfo(item || {});
      
    } else if (action === 2) {
      setXeroStatus(false);
      //console.log("item",item)
      //show Xero options to send purchase Invoice to Xero
      setShowSendPurchaseInvXero((prev) => !showSendPurchaseInvXero);
      updatePurchasePaymentInfo(item || {});
    } else if (action === 3) {
      //setShowPplanSigned((prev) => !showPplanSigned);
    } else {
      //setShowPplanSignedDetails((prev) => !showPplanSignedDetails);
    }
  };

  //Function to split the name into first and last--------------------------------------------------------------
  const splitName = (fullName) => {
    if(fullName){
      const nameParts = fullName.trim().split(" ");
      const firstName = nameParts[0] || "";
      const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
      return { firstName, lastName };
    }
  };

  //END Function to split the name into first and last-----------------------------------------------------------

  //Function to Connect To Xero accountancy package--------------------------------------------------------------
  const handleConnectXero = async () => {
    try {
      // Capture the current page URL
      const currentUrl = window.location.href; // this is to manage Xero authentication redirection in the future

      const response = await axios.get(
        variables.API_URL + "comunication/connect",
        {
          params: { redirectBackUrl: currentUrl },
        }
      );

      // Open the Xero login page in a new tab
      window.open(
        response.data.authUrl,
        "_blank",
        "width=600,height=700,top=100,left=100"
      );

      //console.log("Full Response...", response);
      //console.log("Only Response Data...", response.data);

      {
        if (response.data.success) {
          console.log("conected to Xero!");
          // Reset form or handle success as needed
        } else {
          console.log("Failed to connect to Xero. Please try again.");
        }
      }
    } catch (error) {
      console.error(
        "Error Connecting to Xero.. this is a Front end message:",
        error
      );
    }
  };
  //END Function to Connect To Xero accountancy package--------------------------------------------------------------

  // Handle Sending Purchaseinvoice to Xero Accountantcy package--------------------------------------------------
  const handleXero = async () => {

    //console.log("currentPurchaseInvoceSelected...", currentPurchaseInvoceSelected)
    
    const { firstName, lastName } = splitName(currentSupplierSelected.selectedSupplier.SUPPLIER_CONTACTNAME);

    //console.log("allPurchaseInvoicesWithPaymentsFull",allPurchaseInvoicesWithPaymentsFull)

    const purchaseInvoiceDataXero = {
      name: currentSupplierSelected.selectedSupplier.SUPPLIER_CONTACTNAME,
      company: currentSupplierSelected.selectedSupplier.SUPPLIER_NAME,
      supplierid: currentSupplierSelected.selectedSupplier.SUPPLIER_ID,
      email: currentSupplierSelected.selectedSupplier.SUPPLIER_EMAIL,
      address1: currentSupplierSelected.selectedSupplier.SUPPLIER_ADDRESS1,
      address2: currentSupplierSelected.selectedSupplier.SUPPLIER_ADDRESS2,
      town: currentSupplierSelected.selectedSupplier.SUPPLIER_TOWN,
      post: currentSupplierSelected.selectedSupplier.SUPPLIER_POSTCODE,
      phoneNumber: currentSupplierSelected.selectedSupplier.SUPPLIER_TEL,
      firstName,
      lastName,

      invNumber: currentPurchaseInvoceSelected.selectedPurchaseInvoice[0].PURCHASEINVOICE_NUMBER,
      net: currentPurchaseInvoceSelected.selectedPurchaseInvoice[0].PURCHASEINVOICE_NETPRICE,
      vat: currentPurchaseInvoceSelected.selectedPurchaseInvoice[0].PURCHASEINVOICE_VAT,
      total: currentPurchaseInvoceSelected.selectedPurchaseInvoice[0].PURCHASEINVOICE_TOTAL,
      invoiceDate: currentPurchaseInvoceSelected.selectedPurchaseInvoice[0].PURCHASEINVOICE_DATE,
      description: currentPurchaseInvoceSelected.selectedPurchaseInvoice[0].PURCHASEINVOICE_DESCRIPTION,
      payDate:currentPurchaseInvoceSelected.selectedPurchaseInvoice[0].PURCHASEINVOICE_PAYDAY,
      taxRate:currentPurchaseInvoceSelected.selectedPurchaseInvoice[0].PURCHASEINVOICE_VAT === 0 ? "ZERORATEDINPUT" : "INPUT2"

    };

      
      try {
        //const response = await axios.get(variables.API_URL + "comunication/listinvoicexero")
        //const response = await axios.post(variables.API_URL + "comunication/sendinvoicexero", invoiceDataXero)
        //const response = await axios.get(variables.API_URL + "comunication/listcontactsxero")
        //const response = await axios.post(variables.API_URL + "comunication/newcontactxero", invoiceDataXero)
        //const response = await axios.post(variables.API_URL + "comunication/newinvoicexero",purchaseInvoiceDataXero);
        //const response = await axios.get(variables.API_URL + "comunication/listpurchaseinvoicexero")
        const response = await axios.post(variables.API_URL + "comunication/newpurchaseinvoicexero",purchaseInvoiceDataXero);
        
        //console.log("Full Response purchase...", response);
        //console.log("Only Response purchase Data...", response.data);
        
        {
        if (response.data.success) {
          setXeroStatus(true);
          console.log("Invoice created successfully in Xero!");
          // Reset form or handle success as needed
        } else {
          setXeroStatus(false);
          console.log("Failed to create Purchase invoice. Please try again.");
        }
      }
    } catch (error) {
      console.error(
        "Error Sending to Xero.. this is a Front end message:",
        error
      );
    }
  };
  // END Handle Sending Purchaseinvoice to Xero Accountantcy package--------------------------------------------------
  
  

  const Paymenticons = [
    <AccountBalanceOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <ReceiptLongOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <CreditCardOffOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <ContactlessOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <AlternateEmailOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PhonelinkRingIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
  ];

  //POP OVER CODE --------------------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);

  const [open1, setOpen1] = useState(false);

  // const handlePopoverOpen = (event, reference) => {
  //   //console.log(reference)
  //   setAnchorEl(event.currentTarget);
  //   setPaymentRef(reference);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  //   setPaymentRef("");
  // };

  const handleCollapse = (event, item) => {
    event.preventDefault();
    //console.log("el eventoooooo....", event.currentTarget);
    //console.log("el eventoooooote....", item.PURCHASEINVOICE_ID);

    setOpen1((prev) => ({
      ...prev,
      [item.pOrder.PURCHASEINVOICE_ID]: !prev[item.pOrder.PURCHASEINVOICE_ID],
    }));

    //test to be deleted------
    //paymentListFiltered(0)

    //setOpen1((prev) => !open1);
  };

  //Filter payments list by Payment Type i.e Card payments / BACS / Cheques / GC ------------
  //   const paymentListFiltered = (payType)=>{

  //     // if(payType===5){
  //       //for CARD payments--------
  //       const purchaseInvBySupplierByDateFiltered_current = purchaseInvBySupplierByDate.filter((item)=>item.CLIENTPAYMENTTYPE_ID === payType)
  //       console.log("here is the filtered list for card paymetns....",purchaseInvBySupplierByDateFiltered_current)

  //     // }
  //   }
  //END Filter payments list by Payment Type i.e Card payments / BACS / Cheques / GC --------

  const open = Boolean(anchorEl);

  //Steps for Add a Signed Payment Plan to the order-------------------------------------
  const steps_AddPurchasePayment = [
    {
      label: "Purchase Payment Details",
      info: (
        <AddPurchasePaymentStep_1
          //usersTitleList={usersTitleList}
          purchasePaymentsTypeList={purchasePaymentsTypeList}
        />
      ),
      icon: <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <PaymentOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 0,
    },
    {
      label: "Summary",
      info: <AddPurchasePaymentStep_2 />,
      //info: "Payment Summary component...",
      icon: <ReceiptOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <ReceiptOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 1,
    },
    {
      label: "Confirmation",
      info: (
        <AddPurchasePaymentStep_3
          socket={socket}
          //userInfo={userInfo}
          //showAddPaymentModalHandler={showAddPaymentModalHandler}
          showPurchasePaymentModalHandler={showPurchasePaymentModalHandler}
          //isNewPurchasePayment={isNewPurchasePayment}
          isUpdatedPurchasePayment={isUpdatedPurchasePayment}
        />
      ),
      //info: "Payment comfirmation component...",
      icon: <StorageOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <StorageOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 2,
    },

    //END Function to Connect To Xero accountancy package-----------------------------------------------------------

    // {
    //   label: "Purchase Payment Details",
    //   info: (
    //     <ChangePayDateStep_1
    //     // usersTitleList={usersTitleList}
    //     //paymentTypeList={paymentTypeList}
    //     />
    //   ),
    //   icon: (
    //     <DriveFileRenameOutlineIcon sx={{ color: "#e7dddd", fontSize: 30 }} />
    //   ),
    //   iconCompleted: (
    //     <DriveFileRenameOutlineIcon sx={{ color: "#7A1125", fontSize: 30 }} />
    //   ),
    //   id: 0,
    // },
    // {
    //   label: "Summary",
    //   info: <ChangePayDateStep_2 />,
    //   icon: <ReceiptOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
    //   iconCompleted: (
    //     <ReceiptOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
    //   ),
    //   id: 1,
    // },
    // {
    //   label: "Confirmation",
    //   info: (
    //     <ChangePayDateStep_3
    //       //socket={socket}
    //       //userInfo={userInfo}
    //       showModalHandler={showModalHandler}
    //       isUpdatedPayment={isUpdatedPayment}
    //       //updatePayment={updatedPayment}
    //       //newPaymentPlan={newPaymentPlan}
    //       isLoading1={isLoading1}
    //       //isLoading1_PayplanOnOrderHandler={isLoading1_PayplanOnOrderHandler}
    //     />
    //   ),
    //   //info: "Payment comfirmation component...",
    //   icon: <StorageOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
    //   iconCompleted: (
    //     <StorageOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
    //   ),
    //   id: 2,
    // },
  ];

  //Code to update Order selected slice with total paid on Order--------
  useEffect(() => {
    //console.log("inside use effect to update total paid...", paymentsOnOrder)

    if (purchaseInvBySupplierByDate?.length > 0) {
      setTotalPayments(
        purchaseInvBySupplierByDate[purchaseInvBySupplierByDate.length - 1]
          .cumulative_sum
      );
    } else {
      setTotalPayments(0);
    }

    // dispatch(
    //   purchasePaymentOutActions.updatePurchasePaymentOut({
    //     selectedOrderTotalPaid: totalPaidOnOrder,
    //   })
    // );
  }, [purchaseInvBySupplierByDate]);

  if (showPayDate) {
    variableDate1 = "PayDate";
    variableDate2 = "Inv.Date";
  } else {
    variableDate1 = "Inv.Date";
    variableDate2 = "PayDate";
  }

  return (
    //paymentsByDateList.length > 0 ?
    <Fragment>
      <Box
        sx={{
          display: "flex",
          //flexDirection: matches ? "column" : "row",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={(event) => {
            event.preventDefault();
            showPaymentsHandler(event);
          }}
          sx={{
            color: "#7A1125",
            textTransform: "capitalize",
            marginBottom: 2,
          }}
        >
          <CreditScoreOutlinedIcon
            sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
          />

          {showPurchaseInvoicesList ? "Hide Invoices" : "Show Invoices"}
        </Button>
      </Box>

      {showPurchaseInvoicesList ? (
        !isLoading1 ? (
          <Box
            sx={{
              display: "flex",
              //flexDirection: matches ? "column" : "row",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Title>Purchase Invoices ...</Title>

            <Divider sx={{ width: "60vw" }} />

            {/* {purchaseInvBySupplierByDate?.length > 0 ? ( */}
            {allPurchaseInvoicesWithPaymentsFull?.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{ width: matches2 ? "80vw" : "60vw", marginTop: 2 }}
              >
                {/* <Table aria-label="collapsible table"> */}
                <MyTable size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">..</StyledTableCell>
                      <StyledTableCell align="left">
                        {variableDate1} &nbsp; .
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Inv.No. &nbsp; ........
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {variableDate2} &nbsp; .
                      </StyledTableCell>
                      {/* <StyledTableCell align="right">N.Days(PayDay)</StyledTableCell> */}
                      <StyledTableCell align="right">
                        Net &nbsp;(£)
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Vat &nbsp;(£)
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Total &nbsp;(£)
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Paid &nbsp;(£)
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Cr. &nbsp; .
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        P.O &nbsp; .
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Status &nbsp; .
                      </StyledTableCell>
                      <StyledTableCell align="center">..</StyledTableCell>
                      <StyledTableCell align="center">..</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {/* {orderInfo1.map((info) => ( */}
                    {/* {purchaseInvBySupplierByDate.map((item) => { */}
                    {allPurchaseInvoicesWithPaymentsFull?.map((item) => {
                      //console.log("this is the item inside the map.....",item)

                      showPayDate
                        ? (repDate = item.pOrder.PURCHASEINVOICE_PAYDAY)
                        : (repDate = item.pOrder.PURCHASEINVOICE_DATE);
                      showPayDate
                        ? (repDate1 = item.pOrder.PURCHASEINVOICE_DATE)
                        : (repDate1 = item.pOrder.PURCHASEINVOICE_PAYDAY);
                      return (
                        <Fragment>
                          <MyTableRow key={item.PURCHASEINVOICE_ID}>
                            <TableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                //onClick={() => setOpen1(!open1)}
                                onClick={(event) => handleCollapse(event, item)}
                              >
                                {open1[item.pOrder.PURCHASEINVOICE_ID] ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 10 : 12 }}>
                                {new Date(repDate).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 10 : 12 }}>
                                {item.pOrder.PURCHASEINVOICE_NUMBER}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 10 : 12 }}>
                                {new Date(repDate1).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 10 : 12 }}>
                                {/* £{" "} */}
                                {parseFloat(
                                  item.pOrder.PURCHASEINVOICE_NETPRICE
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                })}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 10 : 12 }}>
                                {/* £{" "} */}
                                {parseFloat(
                                  item.pOrder.PURCHASEINVOICE_VAT
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                })}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                sx={{
                                  fontSize: matches ? 10 : 12,
                                  fontWeight: "bold",
                                }}
                              >
                                {/* £{" "} */}
                                {parseFloat(
                                  item.pOrder.PURCHASEINVOICE_VAT +
                                    item.pOrder.PURCHASEINVOICE_NETPRICE
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                })}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 10 : 12 }}>
                                {/* £{" "} */}
                                {parseFloat(item.totalPaid).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </Typography>
                            </TableCell>

                            {/* <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                ...
                              </Typography>
                            </TableCell> */}

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                ...
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                ...
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                {/* {(parseFloat(item.pOrder.PURCHASEINVOICE_VAT) + parseFloat(item.pOrder.PURCHASEINVOICE_NETPRICE) - parseFloat(item.totalPaid)) === parseFloat(0) && */}
                                {Math.round(
                                  (item.pOrder.PURCHASEINVOICE_VAT +
                                    item.pOrder.PURCHASEINVOICE_NETPRICE -
                                    item.totalPaid) *
                                    10
                                ) /
                                  10 ===
                                  0 && (
                                  <CheckCircleOutlineIcon
                                    sx={{
                                      fontSize: 25,
                                      color: theme.palette.common.okGreen,
                                      marginLeft: 1,
                                    }}
                                  />
                                )}

                                {Math.round(
                                  item.pOrder.PURCHASEINVOICE_VAT +
                                    item.pOrder.PURCHASEINVOICE_NETPRICE -
                                    item.totalPaid
                                ) < 0 && (
                                  <ReportGmailerrorredIcon
                                    sx={{
                                      fontSize: 25,
                                      color: theme.palette.common.ludisRed2,
                                      marginLeft: 1,
                                    }}
                                  />
                                )}

                                {Math.round(
                                  item.pOrder.PURCHASEINVOICE_VAT +
                                    item.pOrder.PURCHASEINVOICE_NETPRICE -
                                    item.totalPaid
                                ) > 0 &&
                                  parseFloat(item.totalPaid) > 0 && (
                                    <RunningWithErrorsIcon
                                      sx={{
                                        fontSize: 25,
                                        color: theme.palette.common.arcOrange,
                                        marginLeft: 1,
                                      }}
                                    />
                                  )}
                              </Typography>
                            </TableCell>

                            {/* <TableCell>
                              <Typography
                                aria-owns={
                                  open ? "mouse-over-popover" : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={(event) =>
                                  handlePopoverOpen(event, item.CHEQUEREF)
                                }
                                onMouseLeave={handlePopoverClose}
                                sx={{ fontSize: matches ? 11 : 14 }}
                              >
                                {matches
                                  ? //   ? item.CHEQUEREF.substring(0, 30)
                                    //   : item.CHEQUEREF.substring(0, 60)
                                    item.COMPANY_NAME.substring(0, 30)
                                  : item.COMPANY_NAME.substring(0, 60)}
                              </Typography>
                            </TableCell> */}
                            {/* <StyledTableCell align="right">
                              {matches ? "" : item.CLIENTPAYMENTTYPE_REF}{" "}
                              {Paymenticons[item.CLIENTPAYMENTTYPE_ID - 1]}
                            </StyledTableCell> */}

                            <TableCell align="left">
                              <Button
                                //key = {item.CLIENTPAYMENT_ID}
                                onClick={(event) => {
                                  event.preventDefault();
                                  showPurchasePaymentModalHandler(
                                    event,
                                    1,
                                    item
                                  );
                                }}
                                sx={{
                                  color: theme.palette.common.ludisMain,
                                  //textTransform: "capitalize",
                                  marginBottom: 2,
                                  marginTop: 2,
                                }}
                              >
                                <ManageAccountsIcon
                                  sx={{
                                    fontSize: 25,
                                    //color: "#4E1721",
                                    marginLeft: 0,
                                  }}
                                />
                              </Button>
                            </TableCell>

                            <TableCell align="left">
                              <Button
                                onClick={(event) => {
                                  event.preventDefault();
                                  showPurchasePaymentModalHandler(
                                    event,
                                    2,
                                    item
                                  );
                                  //showComunicationModalHandler(event, 1);
                                  //handleXero(invoiceNumber);
                                }}
                                sx={{
                                  color: xeroStatus ? "#006400" : "#06b3e8",
                                  textTransform: "capitalize",
                                }}
                                // startIcon={<img src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/xero-logo_kO7aNBmtzD.svg?updatedAt=1738761042437"
                                // alt="iconxero"
                                // style={{ width: 20, height: 20 }}/>}
                                endIcon={
                                  <img
                                    src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/xero-logo_kO7aNBmtzD.svg?updatedAt=1738761042437"
                                    alt="iconxero"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginTop: 5,
                                      marginLeft: 0,
                                    }}
                                  />
                                  // xeroStatus ? (
                                  //   <img
                                  //     src="https://ik.imagekit.io/bwcdq46tkc8/s4b-consulting/ok_YMgi1R5Ar.png?updatedAt=1633510133585"
                                  //     alt="iconxero"
                                  //     style={{
                                  //       width: 20,
                                  //       height: 20,
                                  //       marginTop: 5,
                                  //     }}
                                  //   />
                                  // ) : (
                                  //   <img
                                  //     src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/xero-logo_kO7aNBmtzD.svg?updatedAt=1738761042437"
                                  //     alt="iconxero"
                                  //     style={{
                                  //       width: 20,
                                  //       height: 20,
                                  //       marginTop: 5,
                                  //       marginLeft: 0,
                                  //     }}
                                  //   />
                                  // ) // If false, show an emoji instead of an image
                                }
                              ></Button>
                            </TableCell>
                          </MyTableRow>

                          {open1[item.pOrder.PURCHASEINVOICE_ID] && (
                            <DisplayExtraInfo open1={open1} item={item} />
                          )}
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </MyTable>
                {/* </Table> */}
              </TableContainer>
            ) : (
              <CreditCardOffIcon
                sx={{ color: "#e7dddd", fontSize: 30, marginTop: 2 }}
              />
            )}
          </Box>
        ) : (
          <Box textAlign="center">
            <SyncLoader color="#7A1125" size={15} />
          </Box>
        )
      ) : (
        ""
      )}

      {/* Modal for case: Show Purchase Invoice Details ---------------------- */}
      <Dialog
        open={showAddPurchasePayment}
        //open={open}
        //onClose={handleClose}
        onClose={(event) => showPurchasePaymentModalHandler(event, 1)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle>Purchase Payment Details</DialogTitle>
        <DialogContent>
          {currentPurchaseInvoceSelected.selectedPurchaseInvoice.length > 0 && (
            <>
              <DialogContentText sx={{ margin: 2 }}>
                Purchase Invoice...
                {
                  currentPurchaseInvoceSelected.selectedPurchaseInvoice[0]
                    .PURCHASEINVOICE_NUMBER
                }
              </DialogContentText>
              <DialogContentText sx={{ margin: 2 }}>
                Supplier:{" "}
                {currentSupplierSelected.selectedSupplier.SUPPLIER_NAME}
              </DialogContentText>
            </>
          )}
          {/* <AddPplanSignedSteps steps={steps_signed} /> */}
          {/* Add a new Purchase Payment ... */}
          {/* <ChangePayDateSteps
            steps={steps_AddPurchasePayment}
            showModalHandler={showModalHandler}
          /> */}

          <AddPaymentSteps steps={steps_AddPurchasePayment} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      {/* Modal for case: Show Supplier details to send to Xero---------------------- */}
      <Dialog
        //open={showPplanSignedDetails}
        open={showSendPurchaseInvXero}
        //open={open}
        //onClose={handleClose}
        onClose={(event) => showPurchasePaymentModalHandler(event, 2)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          sx={{
            color: "#4E1721",
            textTransform: "capitalize",
            margin: "auto",
          }}
        >
          Purchase Invoice Details
        </DialogTitle>
        {/* <DialogTitle
          sx={{
            color: "#7A1125",
            textTransform: "capitalize",
            margin: "auto",
          }}
        >
          Purchase Invoice:{" "}
          {
            //currentPurchaseInvoceSelected?.selectedPurchaseInvoice[0].PURCHASEINVOICE_NUMBER
          }
        </DialogTitle> */}
        <DialogContent>
        {currentPurchaseInvoceSelected.selectedPurchaseInvoice.length > 0 && (
            <Fragment>
              <>
                <DialogContentText sx={{ margin: 2 }}>
                  Purchase Invoice...
                  {
                    currentPurchaseInvoceSelected.selectedPurchaseInvoice[0]
                      .PURCHASEINVOICE_NUMBER
                  }
                </DialogContentText>
                <DialogContentText sx={{ margin: 2 }}>
                  Supplier:{" "}
                  {currentSupplierSelected.selectedSupplier.SUPPLIER_NAME}
                </DialogContentText>
              </>
              <Box
                display="flex"
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
              >
                {/* <a href="http://localhost:5000/comunication/connect"> Connect to Xero</a> */}
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    handleConnectXero();
                  }}
                  sx={{ color: "#06b3e8", textTransform: "capitalize" }}
                  startIcon={
                    <img
                      src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/xero-logo_kO7aNBmtzD.svg?updatedAt=1738761042437"
                      alt="iconxero"
                      style={{ width: 20, height: 20, marginTop: 15 }}
                    />
                  }
                >
                  <Typography
                    align="center"
                    //variant="body2"
                    //color="text.secondary"
                    sx={{ marginTop: 2, fontSize: 12 }}
                  >
                    {/* {`Connect to Xero ${invoiceNumber === 3 ? "full" : invoiceNumber}`} */}
                    {`Connect to Xero`}
                  </Typography>
                </Button>

                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    //showComunicationModalHandler(event, 2);
                    handleXero();
                  }}
                  sx={{
                    color: xeroStatus ? "#006400" : "#06b3e8",
                    textTransform: "capitalize",
                  }}
                  // startIcon={<img src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/xero-logo_kO7aNBmtzD.svg?updatedAt=1738761042437"
                  // alt="iconxero"
                  // style={{ width: 20, height: 20 }}/>}
                  endIcon={
                    xeroStatus ? (
                      <img
                        src="https://ik.imagekit.io/bwcdq46tkc8/s4b-consulting/ok_YMgi1R5Ar.png?updatedAt=1633510133585"
                        alt="iconxero"
                        style={{ width: 20, height: 20, marginTop: 15 }}
                      />
                    ) : (
                      <img
                        src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/xero-logo_kO7aNBmtzD.svg?updatedAt=1738761042437"
                        alt="iconxero"
                        style={{
                          width: 20,
                          height: 20,
                          marginTop: 15,
                          marginLeft: 10,
                        }}
                      />
                    ) // If false, show an emoji instead of an image
                  }
                >
                  <ShareIcon
                    sx={{
                      fontSize: 20,
                      //color: !selectedOrder.length ? "#ccc" : "#4E1721",
                      //color: !invoiceRecorded ? "#ccc" : "#4E1721",
                      marginRight: 2,
                      marginTop: 2,
                    }}
                  />
                  <Typography
                    align="center"
                    //variant="body2"
                    //color="text.secondary"
                    sx={{ marginTop: 2, fontSize: 12 }}
                  >
                    {`Send Supplier Invoice to Xero -- ${currentPurchaseInvoceSelected.selectedPurchaseInvoice[0].PURCHASEINVOICE_NUMBER}`}
                  </Typography>
                </Button>
              </Box>

              {/* <DialogContentText sx={{ margin: 2 }}>
                Purchase Invoice...
                {
                  currentPurchaseInvoceSelected.selectedPurchaseInvoice[0]
                    .PURCHASEINVOICE_NUMBER
                }{" "}
                -- Supplier:{" "}
                {currentSupplierSelected.selectedSupplier.SUPPLIER_NAME}
              </DialogContentText> */}

              {/* <PaymentPlanInfo currentPaymentPlanInfo={currentPaymentPlanInfo}/> */}
            </Fragment>
          )}
          {/* <AddPplanSignedSteps steps={steps_signed} /> */}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      {/* END Modal for case: Show Supplier details to send to Xero---------------------- */}
    </Fragment>
  );
}
